import { Link } from 'react-router-dom';
import {
  DefaultColorLogo,
  // headerLogo,
  IconDownArrow,
  IconNewDesign,
  // navCubeIcon,
  NavCubeIconComponent,
  navProfileIcon,
  // newIconDefault,
} from 'assets/image';
import { Fragment, Suspense, useEffect, useRef, useState } from 'react';
import { useApolloClient, useReactiveVar } from '@apollo/client';
import { isLoggedInVar } from 'lib/apollo/apollo';

import useShowProfile from 'hooks/User/useShowProfile';

import useUserChannel from 'hooks/Common/useUserChannel';
import usePageHistory from 'hooks/Common/usePageHistory';
import { useCloseClickOutside } from 'hooks/Common/useCloseClickOutside';

import { showLogin, showSignUp } from 'store/Member';

import { getClass } from 'util/tailwind';
import { retryLazy } from 'util/LazyUtil';
import { QUBE_SERVICE_LIST, menuList } from './menu';
import imageErrorHandler from 'util/Common/ImageErrorHandler';
import TrialProfile from '../../Common/User/Trial';
import { SubscriptionType } from '__apollo__generated__/globalTypes';
import sweetAlert from 'prev-components/Alert/sweetAlert';

const Popup = retryLazy(() => import('./Popup'));

function WebNavigation() {
  const { history, pathName } = usePageHistory();
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const client = useApolloClient();
  const { data: userData, loading } = useShowProfile();
  const userChannel = useUserChannel();
  const setUnReadMessageTotalCount = useState<number>(0)[1];

  // broadcastchannel
  useEffect(() => {
    userChannel.onmessage = event => {
      if (event.data.payload.type === 'login-complete') {
        showLogin(false);

        window.location.reload();
        return;
      } else if (event.data.payload.type === 'LOG OUT') {
        isLoggedInVar(false);
        client.resetStore();
        window.location.reload();
        return;
      }
    };
  }, [userChannel, client]);

  // community popup
  const ref = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState('');

  useCloseClickOutside(ref, () => setShowDropdown(''));

  const handleShowDropdown = (name: string) => {
    return setShowDropdown(name);
  };

  const handleJoinBtn = () => {
    showLogin(true);
    showSignUp(true);
  };

  const handleLoginBtn = () => {
    showLogin(true);
  };

  // user profile pop
  const [showUserpop, setShowUserpop] = useState(false);

  // qube pop
  const [showQubepop, setShowQubepop] = useState(false);
  const qubeRef = useRef<HTMLDivElement>(null);
  useCloseClickOutside(qubeRef, () => setShowQubepop(false));
  const handleShowQubepop = () => {
    setShowQubepop(true);
  };

  const handleSubMenu = (url: string) => {
    history.push(url);
  };

  useEffect(() => {
    return () => setUnReadMessageTotalCount(0);
  }, [setUnReadMessageTotalCount]);

  return (
    <header className="text-[#202136] h-[90px] bg-[#FFFFFF] flex items-center justify-between top-0 left-0 z-[1000] px-[45px] tracking-wide sticky min-w-[1600px] text-[17px] border-b-[1px] border-solid border-b-[#E8E8E8]">
      <div className="flex items-center">
        <Link className="flex items-center h-[30px] mr-[70px]" to="/">
          <img className="h-full" src={DefaultColorLogo} alt="헤더 로고" />
        </Link>
        <div className=" flex gap-8 items-center text-[17px] font-medium">
          {menuList.map((item, index) => {
            if (item.submenu) {
              return (
                <div className={getClass('relative cursor-pointer ')} key={`${item.title}_${index}`}>
                  <button
                    className="text-[17px] flex items-center gap-1"
                    onClick={() => handleShowDropdown(item.name)}
                    type="button"
                  >
                    {item.title}
                    {item.hasNew && <IconNewDesign />}
                    <IconDownArrow />
                  </button>

                  {showDropdown === item.name && (
                    <div
                      ref={ref}
                      className={getClass(
                        '  w-[220px]   rounded-xl  bg-white border-[1px]  items-center absolute top-[40px] shadow-baseShadow left-1/2 -translate-x-1/2 z-[100] py-3',
                      )}
                    >
                      {item.submenu?.map(sub => {
                        return (
                          <div
                            onClick={() => {
                              if (sub.comingsoon) {
                                if (
                                  sub.submenuTitle === '운임시황 모니터링' &&
                                  (process.env.REACT_APP_PUBLIC_URL === 'https://dev-larchive.simplogis.com' ||
                                    process.env.REACT_APP_PUBLIC_URL === 'http://localhost:8081')
                                ) {
                                  return handleSubMenu(sub.submenuUrl);
                                }

                                return sweetAlert({
                                  icon: 'info',
                                  title: '해당 서비스 준비 중 입니다.',
                                  description: <div>더 나은 서비스를 제공해 드리기 위해 최선을 다하겠습니다.</div>,
                                });
                              }
                              handleSubMenu(sub.submenuUrl);
                            }}
                            key={sub.submenuUrl}
                            className={getClass(
                              'hover:bg-[#d4d3d3] w-full   flex items-center justify-start px-5 h-[40px]',
                              sub.comingsoon ? 'text-slate-400' : '',
                            )}
                          >
                            {sub.submenuTitle}
                            {sub.isNew && <IconNewDesign />}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            }
            return (
              <div className="relative text-[17px] flex items-center gap-1" key={`${item.url}_${index}`}>
                <div
                  className={getClass('cursor-pointer')}
                  onClick={() => {
                    if (item.url) {
                      if (item.title === '관세검색') {
                        window.open(item.url);
                      } else {
                        history.push(item.url);
                      }
                    }
                  }}
                >
                  {item.title}
                </div>
                {item.hasNew && <IconNewDesign />}
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex items-center gap-3">
        {isLoggedIn && (
          <div className="flex items-center gap-5">
            {(userData?.showProfile?.user?.subscription?.productAlias === SubscriptionType.TRIAL_STANDARD ||
              userData?.showProfile?.user?.subscription?.productAlias === SubscriptionType.FREE) && <TrialProfile />}

            <div className="w-[30px] h-[30px] relative">
              <img
                onClick={() => setShowUserpop(true)}
                onError={e => imageErrorHandler(e, navProfileIcon)}
                className="w-full h-full rounded-full cursor-pointer"
                src={userData?.showProfile?.user?.profilePhoto || navProfileIcon}
                alt="profile-icon"
              />
              {!loading && userData?.showProfile?.user?.larchiveProfile.unreadMessageCount !== 0 && (
                <div className="w-[6px] h-[6px] absolute -top-[6px] right-0 rounded-full bg-baseColor" />
              )}
              {showUserpop && (
                <Suspense fallback={<Fragment></Fragment>}>
                  <Popup setState={setShowUserpop} />
                </Suspense>
              )}
            </div>
          </div>
        )}{' '}
        {!isLoggedIn && (
          <div className="flex items-center gap-3 ">
            <div
              className="px-4 py-2 border-2 border-white border-solid rounded-lg cursor-pointer"
              onClick={handleJoinBtn}
            >
              회원가입
            </div>
            <div className="cursor-pointer" onClick={handleLoginBtn}>
              로그인
            </div>
          </div>
        )}
        <div className="relative">
          <button
            className="p-2 transition-all ease-linear rounded-full focus:bg-baseColor"
            onClick={handleShowQubepop}
          >
            <NavCubeIconComponent stroke={'#6A6E79'} />
          </button>
          {showQubepop && (
            <div
              ref={qubeRef}
              className="w-56 h-20 shadow-baseShadow
            rounded-xl absolute top-[60px]
            flex gap-3 justify-center items-center right-0 z-[100] bg-white"
            >
              {QUBE_SERVICE_LIST.map(qube => (
                <div className="cursor-pointer" onClick={() => qube.url && window.open(qube.url)} key={qube.name}>
                  <div className="flex flex-col items-center ">
                    <img src={qube.icon} alt="qube-icon" />
                    {qube.name}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default WebNavigation;
